
export default function ErrorPage(props) {

  return(

    <div className="container">

      <h1>WOOPS</h1>

      <h3>LOOKS LIKE WE MISSED THAT PUTT. THIS PAGE DOES NOT EXIST</h3>

      <a href="https://puttr.co">Go to puttr.co</a>

    </div>
  );
}
