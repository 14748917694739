import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useParams } from "react-router-dom";
import logo from '../assets/logo_dark.svg';

import LeaderboardCard from './LeaderboardCard';
import './ClubLeaderboard.css';

const perPage = 3;


export default function ClubLeaderboard() {

  const [leaderboards, setLeaderboards] = useState(null);
  const [club, setClub] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [signage, setSignage] = useState(0);
  const [selectedLeaderboard, setSelectedLeaderboard] = useState(null);
  const { clubId } = useParams();

  useEffect(() => {

    var url = new URL(window.location.href);
    const s = url.searchParams.get("signage");
    if (s){
      setSignage(1)
    }

    getClubLeaderboards();
    getClubData();

    const cycleSec = 15 * 1000;//set interval to cycle every 30 seconds
    const refreshSec = 15 * 1000;//set interval to refresh every 5 minutes
    if (s == 1){
      setInterval(cycleLeaderboards, cycleSec);
    }
    setInterval(getClubLeaderboards, refreshSec);

    return () => {

    };
  }, []);

  const cycleLeaderboards = () => {
    //calculate current page
    let cp = currentPage + 1;//increment cp
    let tot = 0;

    console.log("length:", leaderboards.length);

    for (var i=0; i < leaderboards.length; i++){
      if (leaderboards[i].scores.length > 0){
        tot++;
      }
    }

    //increment current page
    if ((cp * perPage) >= tot){//if next
      cp = 0;
    }
    setCurrentPage(cp);

  }
  const getClubLeaderboards = () => {
    var window_url = new URL(window.location.href);
    var days = window_url.searchParams.get("days");

    let url = "https://api.puttr.co/club/leaderboard/games/" + clubId;

    if (days){
      url += "/" + days;
    }

    axios.get(url)
      .then(res => {

        const lb = res.data;

        var gid = window_url.searchParams.get("gameId");

        if (gid){
          for (var i=0; i < lb.length; i++){
            if (lb[i].leaderboard.id == gid){
              setSelectedLeaderboard(lb[i]);
              break;
            }
          }
        }

        setLeaderboards(lb)

      })
  }
  const getClubData = () => {
    const url = "https://api.puttr.co/club/info/" + clubId;
    axios.get(url)
      .then(res => {
        setClub(res.data[0]);
      })
  }
  const selectLeaderboard = (lb) => {
    console.log("selected leaderboard:", lb);
    setSelectedLeaderboard(lb);
  }
  const renderLeaderboards = () => {

    if (selectedLeaderboard){
      return(
        <LeaderboardCard
          leaderboard={selectedLeaderboard}
          clubId={clubId}
          selectLeaderboard={selectLeaderboard}
          fullBoard={true}
          signage={signage}
        />
      );
    }

    if (leaderboards){

      let st = currentPage * perPage;//get starting point
      let newArr = [];

      if (signage == 1){//if signage is 1

        for (var i=st;i<st+perPage;i++){
          if (leaderboards[i])
            newArr.push(leaderboards[i]);
        }
      }

      else {

        for (var i=0;i<leaderboards.length;i++){
          if (leaderboards[i])
            newArr.push(leaderboards[i]);
        }
      }

      return newArr.map((leaderboard, index) => {

        if (leaderboard.scores.length > 0) {
          return (

            <div key={index}>

              <LeaderboardCard
                leaderboard={leaderboard}
                clubId={clubId}
                signage={signage}
                selectLeaderboard={selectLeaderboard}
              />

            </div>
          );

        }

      });

    }

    return(
      <div className="heading">
        <div className="title">LOADING LEADERBOARD...</div>
      </div>
    );

  }
  const renderClubInfo = () => {

    if (club){

      if (club.clubLogo){

        let hc = '#fff';
        if (club.headerColor){
          hc = "#" + club.headerColor;
        }

        return(

          <div className="prow" style={{backgroundColor:hc, padding:10}}>

            <img src={club.clubLogo} width="300" height="80" alt="Logo" />

          </div>

        );
      }
    }
  }
  const renderQrCode = () => {

    if (signage == 1){//if signage is 1

      const regUrl = "https://app.puttr.co/leaderboard/" + clubId + "/0";
      const qrPath = "https://api.qrserver.com/v1/create-qr-code/?data="+regUrl+"&amp;size=100x100"

      return(
        <div className="prow">

          <img src={qrPath} width="100" height="100" alt="QR Code" />

        </div>
      );
    }
  }


  return(

    <Container fluid style={{backgroundColor:'#F4F4F4'}} className="align-items-center justify-content-start">

      {renderClubInfo()}

      <div className="prow" style={{marginTop:'20px'}}>

        <div className="title">LEADERBOARD</div>

      </div>

      <div style={{marginTop:'30px'}} className="prow">

        {renderLeaderboards()}

      </div>

      {renderQrCode()}

      <div style={{marginTop:'30px'}} className="prow">

        <a href="https://puttr.co?utm_source=leaderboard" target="_blank">

        <img src={logo} color='#000' width="180" height="50" className="align-top" alt="PUTTR Logo" />

        </a>

      </div>

    </Container>

  );



}
