import './Tees.css';

export default function Tees(props) {
  const w = props.width;
  const teeSize = (w/5);

  const teeContainer = { alignItems:'center', justifyContent:'center', display:'flex'};
  let baseTee = {width: teeSize, height:teeSize, borderStyle:'solid', borderWidth:1, borderColor:'#fff', borderRadius:(teeSize / 2), marginLeft:20, marginRight:20 };
  let setTee = {backgroundColor:'#fff', width: teeSize, height:teeSize, borderStyle:'solid', borderWidth:1, borderColor:'#fff', borderRadius:(teeSize / 2), marginLeft:20, marginRight:20 };
  let teeRow = {flexDirection:'row', width:props.width, height:(props.width/3)};
  let distanceText = {color:'#fff', fontSize:'100pt'};
  let tee1 = baseTee;
  let tee2 = baseTee;
  let tee3 = baseTee;

  if (props.tee == 1)
    tee1 = setTee;
  else if (props.tee == 2)
    tee2 = setTee;
  else if (props.tee == 3)
    tee3 = setTee;

  return(

    <div style={{alignItems:'center', justifyContent:'center'}}>

      <div className="gameTeeRow">

        <div style={teeContainer}>
          <div style={tee1}></div>
        </div>
        <div style={teeContainer}>
          <div style={tee2}></div>
        </div>
        <div style={teeContainer}>
          <div style={tee3}></div>
        </div>

      </div>

      <div className="gameDistance">{props.distance}</div>

    </div>
  );
}
