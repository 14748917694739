import Tees from './Tees';
import './GameView.css';
import { lightGreen, green, red } from '../Strings';
import BallPath from './BallPath';
import HoleAngle from './HoleAngle';
import { getHoleEntry, getTeeEntry} from '../common/GameFunctions';

export default function Putt(props) {

  const hb = JSON.parse(props.putt.holeBounds);

  const red = "#FF5252";
  const green = "#053220";

  const renderMakeDetails = () => {

    const holeEntry = getHoleEntry(JSON.parse(props.putt.ballPath), JSON.parse(props.putt.holeBounds));
    const teeEntry = getTeeEntry(props.putt.distance, props.putt.tee);

    const angDiff = holeEntry[2] - teeEntry;
    let entrySide = "PERFECT";

    const textStyle = {
      fontSize:10,
      color:'#333',
      textAlign:'center'
    }

    if (props.putt.tee == 2){
      if (angDiff < 0)
        entrySide = "LEFT OF CENTER";
      if (angDiff > 0)
        entrySide = "RIGHT OF CENTER";
    }

    if (props.putt.tee == 1){
      if (angDiff < 0)
        entrySide = "HIGH SIDE";
      if (angDiff > 0)
        entrySide = "LOW SIDE";
    }

    if (props.putt.tee == 3){
      if (angDiff < 0)
        entrySide = "LOW SIDE";
      if (angDiff > 0)
        entrySide = "HIGH SIDE";
    }

    //calc score from center cut
    const startScore = 10;
    const degOff = Math.abs(angDiff);
    const es = ((startScore - (degOff / 10))) * 10;

    return(
      <div style={{alignItems:'center'}}>

        <HoleAngle size={70} teeEntry={teeEntry} score={angDiff} holeEntry={holeEntry} angle={20} />

        <div style={textStyle}>{entrySide}</div>

      </div>
    );
  }

  const renderPuttResult = () => {

    if (props.putt.made == 1) {
      return renderMakeDetails();
    }

    const textStyle = {
      fontSize:10,
      color:'#fff',
      textAlign:'center'
    }

    return(

      <div className="prow">

        <div style={{height:70, width:70, backgroundColor:'#000', borderRadius:35}}>

          <BallPath
            ballPath={props.putt.ballPath} putt={props.putt} width={70}
          />

        </div>

      </div>

    );
  }

  const renderPuttDetails = () => {
    const textStyle = {
      fontSize:10,
      color:'#333',
      textAlign:'center'
    }

    let teeText = "STRAIGHT";
    if (props.putt.tee == 1)
      teeText = "LEFT TO RIGHT";
    if (props.putt.tee == 3)
      teeText = "RIGHT TO LEFT";

    return(

      <div style={{alignItems:'center', marginTop:0}}>

        <div style={{marginTop:5, alignItems:'center'}}>

          <div style={textStyle}>{teeText}</div>

        </div>

      </div>
    );
  }

  let fill = red;
  if (props.putt.made == 1){
    fill = lightGreen;
  }

  let dot = {
    position: 'relative',
    borderColor:'#fff',
    borderStyle:'solid',
    color:'#fff',
    borderWidth: 1,
    left:-5,
    top:-5,
    width:25,
    height:25,
    borderRadius:12.5,
    backgroundColor:fill,
    alignItems:'center',
    justifyContent:'center',
    fontWeight:'bold',
    fontSize:'11pt',
    textAlign:'center'
  };
  //let dot = {borderColor:'#fff'};
  let num = props.index + 1;

  return (

      <div className="puttCard">

        <div style={dot}>{num}</div>

        <div style={{ alignItems:'center', textAlign:'center', height:80}}>

          {renderPuttResult()}

        </div>

        <div style={{ alignItems:'center', marginTop:5}}>

          <Tees tees={props.putt.tee} />

          <div className="distance">{props.putt.distance}</div>

        </div>

        <div style={{marginBottom:5}}>

          {renderPuttDetails()}

        </div>

      </div>

    );

}
