import './App.css';
import React from 'react';
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch,faRandom,faFire,faClock,faBullseye,faTrophy,faVial,faChessKing,faGolfBall,faGlobeAmericas,faBomb,faComments,faTrashAlt,faChartPie,faRecordVinyl } from '@fortawesome/free-solid-svg-icons';

import ErrorPage from './ErrorPage';
import Home from './home/Home';
import Screen from './screen/Screen';
import ClubLeaderboard from './club/ClubLeaderboard';
import GameView from './game/GameView';

library.add(faCircleNotch,faRandom,faFire,faClock,faBullseye,faTrophy,faVial,faChessKing,faGolfBall,faGlobeAmericas,faBomb,faComments,faTrashAlt,faChartPie,faRecordVinyl);

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "screen/",
      element: <Screen />,
    },
    {
      path: "leaderboard/:clubId",
      element: <ClubLeaderboard />,
    },
    {
      path: "game/:gameId",
      element: <GameView />,
    },
  ]);

  return (

    <RouterProvider router={router} />
  );

}

export default App;
