import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import axios from 'axios';

import { socket } from './common/socket';
import { getMakeGif, getMissGif } from './common/GifFunctions';
import StartForm from './StartForm';
import HomeScreen from './home/HomeScreen';
import Standard from './game/Standard';
import Generic from './game/Generic';

export default function Screen(props) {

  const testGameState = {
    status:1,
    tee: 2,
    distance: 7,
    playerNames:['Matt', 'Daniel'],
    playerScores:[17,7]
  }
  const testClubData = {
    clubId:'PUTTR'
  };
  const testCurrentGame = {
    id: 101,
    gameType: 'STANDARD',
    icon:'trophy',
    title: 'Test Game',
    description: 'This is a description'
  }

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [clubId, setClubId] = useState('');
  const [clubData, setClubData] = useState(null);//null
  const [currentScreen, setCurrentScreen] = useState('HOME');//HOME
  const [currentGame, setCurrentGame] = useState(null);//null
  const [currentGameState, setCurrentGameState] = useState(null);//null
  const [puttGif, setPuttGif] = useState('https://storage.googleapis.com/images.puttr.co/animations/make_1.gif');
  const [puttReplayModal, setPuttReplayModal] = useState(false);
  const [roomName, setRoomName] = useState(null);

  useEffect(() => {

    socket.on('connect_error', err => {
      console.log("connect error:", err);
    });
    socket.on('connect_failed', err => {
      console.log("connect failed:", err);
    });
    socket.on("connect", () => {
      //console.log("connected");
      setIsConnected(true);
      //socket.emit("JOIN_ROOM", clubId);
    });
    socket.on("disconnect", () => {
      console.log("socket disconnected");
      setIsConnected(false);
      //socket.open();
    });
    socket.on("message", data => {
      processGameMessage(data);
    });

    //see if query string
    var window_url = new URL(window.location.href);
    var cid = window_url.searchParams.get("clubId");

    if (cid){
      setClub(cid);
    }

    //getClubData(clubId);

    return () => {
      socket.off('connect', () => {
        console.log("turn off connect");
      });
      socket.off('disconnect', () => {
        console.log("turn off disconnect");
      });
      socket.off('message', () => {
        console.log("turn off message");
      });
    };
  }, []);
  const setClub = (cId) => {
    setClubId(cId);
    //console.log("joingin room:", cId);
    socket.emit("JOIN_ROOM", cId);
    getClubData(cId);
  }
  const processGameMessage = (data) => {
    console.log("received socket message:", data);
    if (data.messageType == "CLUB_HOME"){
      setCurrentScreen("HOME");
    }
    else if (data.messageType == "CLUB_GAME_START"){
      setCurrentGame(data.game);
      setCurrentGameState(null);//clear any old state
      //console.log("game data:", data.game);
      setCurrentScreen("GAME_SCREEN");
    }
    else if (data.messageType == "CLUB_GAME_STATE"){
      setCurrentScreen("GAME_SCREEN");
      //console.log("game state:", data.gameState);
      setCurrentGameState(data.gameState);
    }
    else if (data.messageType == "CLUB_GAME_PUTT"){
      handlePutt(data.putt);//makeMiss
    }
    else if (data.messageType == "CLUB_GAME_VIDEO"){
      setRoomName(data.roomName);
    }
  }
  const handlePutt = (putt) => {
    if (putt == 1){
      let g = getMakeGif();
      setPuttGif(g);
    }
    else {
      let g = getMissGif();
      setPuttGif(g);
    }
    setPuttReplayModal(true);
    setTimeout(() => { setPuttReplayModal(false)}, 2000);
  }
  const getClubData = (cId) => {
    const url = "https://api.puttr.co/club/info/" + cId;
    axios.get(url)
      .then(res => {
        setClubData(res.data[0]);
      })
  }
  const renderScreen = () => {

    if (clubId){

      if (currentScreen == "HOME"){
        return(

          <HomeScreen clubData={clubData} />

        );
      }
      else if (currentScreen == "GAME_SCREEN"){

        if (currentGame.gameType == "STANDARD"){

          return(

            <Standard clubData={clubData} game={currentGame} gameState={currentGameState} />

          );

        }

        else {
          return(

            <Generic clubData={clubData} game={currentGame} gameState={currentGameState} />

          );
        }

      }

    }
    else {
      return(
        <StartForm setClubId={setClub} />
      );
    }

  }
  const renderSocketConnection = () => {
    if (isConnected){
      return(
        <div className="label">SOCKET CONNECTED</div>
      );
    }
    else {
      return(
        <div className="label">SOCKET NOT CONNECTED</div>
      );
    }
  }
  const renderReplayModal = () => {
    return(
      <Modal fullscreen={true} backdrop={false} show={puttReplayModal} onHide={() => {setPuttReplayModal(false)}}>

        <Modal.Body style={{backgroundColor:'#000'}}>

          <div style={{backgroundColor:'#000', alignItems:'center', justifyContent:'center', position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)'}}>

            <Image src={puttGif} width={window.innerWidth} />

          </div>

        </Modal.Body>

      </Modal>
    );
  }
  return (
    <div className="container">
      {renderReplayModal()}
      {renderScreen()}
      {renderSocketConnection()}
    </div>
  );
}

/*
<Modal.Body style={{backgroundColor:'#000', alignItems:'center', justifyContent:'center', height:window.innerHeight, position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)'}}>

<Image src={puttGif} width={window.innerWidth} />
*/
