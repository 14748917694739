import React, { useState } from 'react';
import './GameScreen.css';
import Leaderboard from '../../leaderboard/Leaderboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tees from '../common/Tees';
import Scoreboard from '../common/Scoreboard';

export default function Generic(props) {

  const [highScore, setHighScore] = useState(0);//null

  const screenWidth = window.innerWidth-400;
  const renderGameDetails = () => {

    const game = props.game;

    return(

      <div style={{alignItems:'center', justifyContent:'center'}}>

        <div className="gameIcon">

          <FontAwesomeIcon color="#098B54" icon={game.icon} />

        </div>

        <div className="gameScreenTitle">{game.title}</div>

        <div className="gameScreenDescription">{game.description}</div>

        <div style={{marginTop:50}}>

          <div className="gameScreenDescription">THIS GAME TYPE IS NOT CURRENTLY SUPPORTED IN SECOND SCREEN MODE</div>

        </div>

      </div>
    );
  }
  const renderGameScreen = () => {

    const gameIntro = {backgroundColor:'rgba(0,0,0,0.8)',borderRadius:20,marginTop:20,padding:10,height: (window.innerHeight - 100)};

    if (props.gameState){

      if (props.gameState.status === 0){
        return(
          <div style={gameIntro}>

            {renderGameDetails()}

          </div>
        );
      }

      else if (props.gameState.status === 1){

        const putt = JSON.parse(props.game.puttData)[props.gameState.puttCounter];

        return(

          <div style={{marginTop:20}}>

            <Tees width={screenWidth} tee={putt.tee} distance={putt.distance} />

            <Scoreboard game={props.game} gameState={props.gameState} />

          </div>
        );

      }

      else if (props.gameState.status === 2){

        return(

          <div>

            <h1 style={{color:'#fff'}}>GAME OVER</h1>

            <Scoreboard game={props.game} gameState={props.gameState} />

          </div>

        );

      }

    }

    else {

      return(
        <div style={gameIntro}>

          {renderGameDetails()}

        </div>
      );
    }


  }

  if (props.game){

    return(

      <div className="bg" style={{backgroundImage: "url(" + props.game.bgImagePath + ")"}}>

        <div className="screen">

          <div style={{width: screenWidth, padding:20, alignItems:'center', justifyContent:'center'}}>

            {renderGameScreen()}

          </div>

        </div>

      </div>
    );

  }

  return(

    <div>

      <h3>LOADING GAME</h3>

    </div>
  );
}
