import './Scoreboard.css';

export default function Scoreboard(props) {

  const renderProgress = () => {

    return(
      <div className="playerName">
        {props.gameState.puttCounter} / {JSON.parse(props.game.puttData).length}
      </div>
    );
  }

  const renderScores = () => {

    const players = props.gameState.playerNames;

    return players.map((player, index) => {

      const pp = props.gameState.playerPutts[index];//get current players putts
      let score = 0;
      for (let i=0; i<pp.length;i++){
        score += pp[i];
      }

      let borderColor = '#000';

      if (props.gameState.currentPlayer == index){
        borderColor = '#098B54';
      }

      return(

        <div key={index}>

        <div className="scoreFrame" style={{borderColor: borderColor}}>

          <div className="playerName">{player.displayName}</div>

          <div className="score">{score}</div>

        </div>

        </div>
      );

    });

  }


  return(

    <div style={{alignItems:'center', justifyContent:'center'}}>

      <div className="scoreRow">

        {renderScores()}

      </div>

      {renderProgress()}

    </div>
  );
}
