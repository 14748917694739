import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './GameScreen.css';
import GameLeaderboard from '../leaderboard/GameLeaderboard';
import Tees from '../common/Tees';
import Scoreboard from '../common/Scoreboard';

export default function Standard(props) {

  const [highScore, setHighScore] = useState(0);//null

  const screenWidth = window.innerWidth-400;
  const gameIntro = {backgroundColor:'rgba(0,0,0,0.8)',borderRadius:20,marginTop:20,padding:10,height: (window.innerHeight - 100)};

  const renderGameIntro = () => {

    return(

      <div className="screen">

        <div style={{width: screenWidth, padding:20, alignItems:'center', justifyContent:'center'}}>

          <div style={gameIntro}>

            <div style={{alignItems:'center', justifyContent:'center'}}>

              <div className="gameIcon">

                <FontAwesomeIcon color="#098B54" icon={props.game.icon} />

              </div>

              <div className="gameScreenTitle">{props.game.title}</div>

              <div className="gameScreenDescription">{props.game.description}</div>

              <div style={{marginTop:50}}>

                <div className="gameScreenTitle" style={{fontSize:100}}>{highScore}</div>

                <div className="gameScreenDescription">HIGH SCORE</div>

              </div>

            </div>

          </div>

        </div>

        <div className="leaderboard" style={{height:window.innerHeight, borderRadius:20}}>

          <h2 style={{color:'#fff', padding:'10px'}}>LEADERBOARD</h2>

          <GameLeaderboard clubId={props.clubData.clubId} gameId={props.game.id} setHighScore={setHighScore} />

        </div>

      </div>
    );
  }

  const renderGameOver = () => {

    return(

      <div className="screen">

        <div style={{width: screenWidth, padding:20, alignItems:'center', justifyContent:'center'}}>

          <div style={gameIntro}>

            <div style={{alignItems:'center', justifyContent:'center'}}>

              <h1 style={{color:'#fff'}}>GAME OVER</h1>

              <Scoreboard game={props.game} gameState={props.gameState} />

            </div>

          </div>

        </div>

        <div className="leaderboard" style={{height:window.innerHeight, borderRadius:20}}>

          <h2 style={{color:'#fff', padding:'10px'}}>LEADERBOARD</h2>

          <GameLeaderboard clubId={props.clubData.clubId} gameId={props.game.id} setHighScore={setHighScore} />

        </div>

      </div>
    );
  }

  const renderGameScreen = () => {

    if (props.gameState){

      if (props.gameState.status === 0){//game not started

        return renderGameIntro();
      }

      else if (props.gameState.status === 1){

        const putt = JSON.parse(props.game.puttData)[props.gameState.puttCounter];

        return(

          <div style={{marginTop:20}}>

            <div style={{padding:10}}>

              <Tees width={window.innerWidth} tee={putt.tee} distance={putt.distance} />

            </div>

            <Scoreboard game={props.game} gameState={props.gameState} />

          </div>
        );

      }

      else if (props.gameState.status === 2){

        return renderGameOver();

      }

    }

    else {

      return renderGameIntro();

    }


  }

  if (props.game){

    return(

      <div className="bg" style={{backgroundImage: "url(" + props.game.bgImagePath + ")"}}>

          {renderGameScreen()}

      </div>
    );

  }

  return(

    <div>

      <h3>LOADING GAME</h3>

    </div>
  );
}
