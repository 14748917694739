import React from 'react';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ClubLeaderboard.css';

export default function LeaderboardCard(props) {

  const lb = props.leaderboard.leaderboard;
  const sc = props.leaderboard.scores;

  const getPlaceText = (place) => {
    let str = place;
    if (place == 1)
      str += "st";
    if (place == 2)
      str += "nd";
    if (place == 3)
      str += "rd";
    else if (place > 3)
      str += "th";
    return str;
  }
  const renderAvatar = (user) => {
    if (user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={user.photoURL} width="40" height="40" alt="Photo" />
        </div>
      );
    }

    else {
      const i = user.displayName.substring(0,1);
      return(
        <div className="avatar"> {i} </div>
      );

    }
  }
  const renderScores = (scores) => {

    if (scores.length == 0)
      return <div className="title">NO SCORES YET</div>

    let ct = 0;
    let howMany = 5;
    if (props.fullBoard)
      howMany = 1000;

    return scores.map((score, index) => {

      console.log("score:", score);
      const gameLink = "/game/" + score.userGameId;

      if (ct++ < howMany){

        const user = JSON.parse(score.userName);
        const pl = getPlaceText(index+1);

        return(



          <div key={index} className="lbRow">

              <Link to={gameLink}>

                <div className="lbPlace">{pl}</div>

              </Link>

              {renderAvatar(user)}

              <div className="lbDisplayName" style={{ width:200}}>{user.displayName}</div>

              <div className="lbScore">{score.score}</div>


          </div>

        );

      }

    });
  }

  const renderBackLink = () => {

    if ((props.fullBoard) && (!props.signage) ) {
      return(
        <Link to="#" onClick={() => {props.selectLeaderboard(null)}}>

          <div style={{fontSize:'10pt', padding:'10px', color:'#333'}}>GO BACK > </div>

        </Link>
      );
    }

  }
  const renderMoreLink = (lb) => {

    if ( (props.signage != 1) && (!props.fullBoard) ) {

      const ll = "/leaderboard/" + props.clubId + "/" + lb.id;

      return (

        <Link to="#" onClick={() => {props.selectLeaderboard(props.leaderboard)}}>

          <div style={{fontSize:'10pt', padding:'10px', color:'#333'}}>VIEW FULL LEADERBOARD > </div>

        </Link>

      );
    }


  }


  return(

    <div key={lb.id} className="lbCard">

      <div className="lbIcon">

        <FontAwesomeIcon color="green" icon={lb.icon} />

      </div>

      <div className="lbTitle">{lb.title}</div>

      {renderBackLink()}

      <div className="justify-content-start">

        {renderScores(sc)}

      </div>

      {renderMoreLink(lb)}

      {renderBackLink()}

    </div>


  );



}
