import logo from '../assets/logo_dark.svg';

export default function Home(props) {

  return(

    <div className="container">

      <div style={{marginTop:30}}>

        <img src={logo} color='#000' width="360" height="100" className="align-top" alt="PUTTR Logo" />

        <h3>FOR MORE INFORMATION ABOUT PUTTR, GO TO...</h3>

        <a href="https://puttr.co">puttr.co</a>

      </div>

    </div>
  );
}
